.film {
  display: block;
  position: relative;
  width: 100vw;
  background: black;
}
.mobileTitles {
  display: none;
}
#stillsMobile {
  display: none;
}

.frames {
  display: block;
  position: sticky;
  top: 0;
  width: 100vw;
  background: black;
}

.video-gal {
  display: block;
  object-fit: contain;
  width: 60vw;
  height: 100vh;
  border-color: rgba(255, 138, 202, 0.7);
  border-bottom: 2px;
}

.video-gallery {
  display: block;
  height: 100vh;
  position: relative;
}

.gallery-text {
  background-color: rgba(255, 138, 202, 0.7);
  font-size: 0.4em;
  position: absolute;
  top: 85vh;
  width: 30vw;
  height: max-content;
  margin-left: 25%;
  margin-right: 25%;
  padding-left: 0vh;
  padding-right: 0vh;
  z-index: 1500;
  text-align: center;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.embla__dot,
.embla__button {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
}
.embla__dots {
  z-index: 0;
  bottom: 1.2rem;
  position: relative;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.embla__dot {
  width: 3rem;
  height: 0rem;
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.embla__dot:after {
  background: rgba(255, 138, 202, 0.3);
  border-radius: 0.2rem;
  width: 100%;
  height: 0.3rem;
  content: "";
}
.embla__dot--selected:after {
  background: linear-gradient(
    45deg,
    rgba(255, 138, 202, 0.7),
    rgba(255, 138, 202, 0.7)
  );
}
.embla__button {
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 150%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 4rem;
  height: 4rem;
}
.embla__button--prev {
  left: 1.6rem;
}
.embla__button--next {
  right: 1.6rem;
}
.embla__button:disabled {
  opacity: 0.3;
}
.embla__button__svg {
  width: 65%;
  height: 65%;
}

.embla {
  --slide-spacing: 4rem;
  --slide-size: 60%;
  --slide-height: 19rem;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  flex-direction: row;
  height: auto;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}
.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
}
.embla__slide__number {
  width: 4.6rem;
  height: 4.6rem;
  z-index: 1;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 4.6rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}
.embla__slide__number > span {
  color: var(--brand-primary);
  background-image: linear-gradient(
    45deg,
    var(--brand-primary),
    var(--brand-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.6rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.embla__lazy-load {
  position: relative;
  height: 100%;
}
.embla__lazy-load__spinner {
  border: 0.4rem solid rgba(var(--text-high-contrast-rgb-value), 0.2);
  border-left: 0.4rem solid var(--text-high-contrast);
  font-size: 1rem;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  text-indent: -9999em;
  animation: loading 1.1s infinite linear;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
}
.embla__lazy-load__spinner:after {
  border-radius: inherit;
  width: 5rem;
  height: 5rem;
}
.embla__lazy-load__img {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.embla__lazy-load--has-loaded .embla__lazy-load__img {
  opacity: 1;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1366px) {
  .embla {
    --slide-spacing: 9rem;
    --slide-size: 100%;
    --slide-height: 5rem;
  }
  .video-gal {
    display: block;
    object-fit: contain;
    width: 30vw;
    height: 100vh;
    border-color: rgba(255, 138, 202, 0.7);
    border-bottom: 2px;
  }
  .mobileTitles {
    display: none;
  }
  #stillsMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  #gifsmobile {
    width: 100vw;
  }

  #films {
    display: none;
    width: 10vw;
  }

  .video-gallery {
    height: auto;
  }

  .video-gal {
    width: 100vw;
    height: auto;
  }
  .stills {
    display: none;
  }
  #stillsMobile {
    display: block;
    margin-top: 10px;
    height: auto;
    padding-bottom: 0vh;
  }
  .mobileTitles {
    display: block;
    color: pink;
    text-align: center;
    text-shadow: 0 0 5px #fff, 0 0 10px #f0f, 0 0 15px #fff, 0 0 20px;
    height: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .gallery-text {
    top: 21vh;
    width: 100vw;
    margin-left: 0;
    margin-right: 25%;
    padding-left: 0vh;
    padding-right: 0vh;
    z-index: 1500;
    text-align: center;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }
}
