html {
  font-family: jaf-domus-titling-web, sans-serif !important;
}

.nav {
  width: 100%;
  position: fixed;
  left: 5vh;
  margin-left: 0;
  top: 43vh;
  font-size: 1.2em;
  line-height: 1em;
  font-weight: 200;
  font-style: normal;
  z-index: 1500;
  cursor: auto;
  display: block;
  text-align: left;
  text-shadow: 0 0 5px #fff, 0 0 10px #f0f, 0 0 15px #fff, 0 0 20px;
}

a:active {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
  color: inherit;
}

a:link {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: pink;
}

.logoText {
  width: 100%;
  position: fixed;
  top: 30px;
  right: 22px;
  font-size: 1em;
  line-height: 1em;
  font-weight: 100;
  font-style: normal;
  z-index: 1800;
  cursor: pointer;
  display: block;
  text-align: left;
  color: white;
  width: 418px;
  height: 50px;
  text-shadow: 0 0 5px #fff, 0 0 10px #f0f, 0 0 15px #fff, 0 0 20px;
}
.logoTextMobile {
  display: none;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.titleSection {
  width: 100%;
  position: fixed;
  top: 30px;
  left: 100px;
  font-size: 3.5em;
  line-height: 1em;
  font-weight: 200;
  font-style: normal;
  z-index: 1900;
  cursor: auto;
  display: block;
  text-align: left;
  color: black;
  text-shadow: 0 0 5px #fff, 0 0 10px #f0f, 0 0 15px #fff, 0 0 20px;
}

.moreButton {
  position: fixed;
  top: 5px;
  left: 30px;
  text-decoration: none;
  font-size: 1.7em;
  line-height: 1em;
  font-weight: 200;
  font-style: normal;
  z-index: 1900;
  cursor: pointer;
  display: block;
  text-align: left;
  color: black;
  text-shadow: 0 0 5px #fff, 0 0 10px #f0f, 0 0 15px #fff, 0 0 20px;
}
.cat {
  display: none;
}
@media screen and (max-width: 1366px) {
  .titleSection {
    display: block;
  }
  .logoTextMobile {
    display: none;
  }
  .logoText {
    display: block;
  }
  .nav {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .titleSection {
    display: none;
  }
  .logoTextMobile {
    display: block;
    top: 10px;
    left: 35vw;
    right: 45vw;
    font-size: 2em;

    width: 100%;
    position: fixed;

    line-height: 1em;
    font-weight: 100;
    font-style: normal;
    z-index: 1800;
    cursor: pointer;
    display: block;
    text-align: left;
    color: white;
    width: 418px;
    height: 50px;
    text-shadow: 0 0 5px #fff, 0 0 10px #f0f, 0 0 15px #fff, 0 0 20px;
  }
}
